import { OptionsActions, OptionsActionTypes } from './options.actions';
import { OptionType } from '../options.model';

export interface OptionsState {
  options: OptionType[];
}

const initialState: OptionsState = {
  options: [],
};

export function optionsReducer(state = initialState, action: OptionsActions): OptionsState {
  switch (action.type) {
    // case OptionsActionTypes.LOAD_OPTIONS:
    //   return {
    //     ...state
    //   };
    case OptionsActionTypes.SET_OPTIONS:
      return {
        ...state,
        options: action.payload
      };
    default: {
      return state;
    }
  }
}






