import { Action } from '@ngrx/store';
import { OptionType } from '../options.model';

export enum OptionsActionTypes {
  LOAD_OPTIONS = '[Options] Load Options',
  SET_OPTIONS = '[Options] Set Options'
}

export class LoadOptions implements Action {
  readonly type = OptionsActionTypes.LOAD_OPTIONS;
}

export class SetOptions implements Action {
  readonly type = OptionsActionTypes.SET_OPTIONS;

  constructor(public payload: OptionType[]) {
  }
}

export type OptionsActions =
  LoadOptions
  | SetOptions;
